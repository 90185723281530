
import { Icon } from '/features/buildingBlocks/Icon'
import arrowIcon from '/images/icons/arrow-down.raw.svg'
import styles from './ScrollIndicator.css'

export function ScrollIndicator({ title = undefined }) {
  return (
    <div className={styles.component}>
      {title && <strong className={styles.heading}>{title}</strong>}
      <div className={styles.icon}>
        <Icon icon={arrowIcon} layoutClassName={styles.innerIcon} />
      </div>
    </div>
  )
}
